import { HTTP } from '../../axios/axios'

async function getAll (lang) {
  try {
    const res = await HTTP.get('/language', { headers: { 'accept-language': lang } })
    return res.data
  } catch (error) {
    console.log(error)
  }
}
async function update (data, lang) {
  return await HTTP.patch('/language', { data }, { headers: { 'accept-language': lang } })
}

export default { getAll, update }
