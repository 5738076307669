<template>
  <div>
    <label for="language">Choose language:
      <select
        class="p-2 outline-none"
        v-model="lang"
        @change="getData(lang)"
        name="language"
      >
        <option value="en">English</option>
        <option value="ru">Russian</option>
        <option value="az">Azerbaijani</option>
      </select>
    </label>
    <div class="p-4">
      <vue-json-editor v-model="data" :mode="'form'" :modes="['form', 'view']" :show-btns="true" :expandedOnStart="true" @json-save="save"></vue-json-editor>
    </div>
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor'
import requests from '../services/requests/LanguageRequests.js'

export default {
  data () {
    return {
      lang: 'en',
      requests: requests,
      data: ''
    }
  },
  name: 'Language',
  components: {
    vueJsonEditor
  },
  methods: {
    // gets data from backend
    save () {
      try {
        this.requests.update(this.data, this.lang)
        this.getData(this.lang)
      } catch (error) {
        console.log(error)
      }
    },
    async getData (lang) {
      this.$emit('loading', true)
      const temp = await this.requests.getAll(lang)
      this.$store.commit('setData', temp)
      this.data = temp
      this.$emit('loading', false)
    }
  },
  created () {
    // fills table with data from backend on created
    this.getData(this.lang).then(res => {
      this.data = this.$store.state.data
    })
  }
}
</script>

<style lang="scss">
  .jsoneditor{
    border: none !important
  }
  div.jsoneditor-tree{
    padding: 5px 10px;
    border: 1px solid rgba(31, 41, 55,1)
  }
  div.jsoneditor-menu{
    height: 45px !important;
    padding: 8px !important;
    border-radius: 8px 8px 0 0;
    background-color:rgba(31, 41, 55, 1) !important;
    border: none;
    .jsoneditor-search{
      margin-right: 10px;
      margin-top: 6px;
    }
    .jsoneditor-type-modes{
      height: 30px !important;
      padding-left: 5px;
      &.jsoneditor-selected{
        background-color:rgba(31, 41, 55, 0.7) !important;
      }
    }
  }
  button{
    width: auto !important;
    &::after{
      color:white;
      width: 20px;
      height: 10px;
      padding: 0px 3px;
    }
    &.jsoneditor-type-modes{
      width:124px !important
    }
    &.jsoneditor-expand-all::after{
      content:'expand' !important
    }
    &.jsoneditor-collapse-all::after{
      content:'collapse' !important
    }
    &.jsoneditor-undo::after{
      content:'undo' !important
    }
    &.jsoneditor-redo::after{
      content:'redo' !important
    }
  }
</style>
